
type EmptyEventProps = {
  title?: string
  description?: string
}

export function EmptyEvent(props: EmptyEventProps): React.JSX.Element {
  const {
    title = "There is no event at this time",
    description = "Stay tuned for our upcoming events!",
  } = props

  return (
    <div className="w-full h-full flex justify-center items-center rounded-3xl bg-sta-cloud px-8 py-12 sm:px-16 sm:py-16 2xl:py-32 3xl:px-32 3xl:py-32">
      <div className="flex flex-col justify-center items-center gap-4 md:gap-8">
        <img alt="event" src={`/image/calendar-frown.svg`} className="w-[100px] md:w-[150px] h-[100px] md:h-[150px]" />
        <div className="flex flex-col justify-center items-center text-center gap-2 md:gap-6">
          <p className="font-bold text-lg md:text-2xl text-sta-primary">
            {title}
          </p>
          <p className="text-base md:text-lg">
            {description}
          </p>
        </div>
      </div>
    </div>
  )
}
