import {
  Status,
  ACTION_SUCCESS,
  UNEXPECTED_ERROR,
} from "../Status"
import {
  SubscribeEmailParam, SubscribeEmailResult
} from "../Newsletter"

export type NewsletterConfig = {
  host: string
}

export class Newsletter {

  constructor(private config: NewsletterConfig) { }

  public async SubscribeEmail(p: SubscribeEmailParam): Promise<SubscribeEmailResult> {
    try {
      const url = `${this.config.host}/api/v1/newsletter/subscribe`

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          verification_token: p.verificationToken,
          email: p.email,
        }),
        redirect: "follow",
      })
      if (response.status !== 202) {
        const error = await Status.FromResponse(response)
        return {
          error,
        }
      }

      return {
        success: new Status("success subscribe newsletter", ACTION_SUCCESS),
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }
}
