import * as React from "react"
import { Outlet, useLocation } from "react-router-dom"
import { RiLinkedinFill, RiFacebookFill, RiInstagramFill } from "react-icons/ri"
import { IoCallOutline, IoLocationOutline, IoAtOutline } from "react-icons/io5"
import { PiWhatsappLogo } from "react-icons/pi"
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { Link } from "../components/Router"
import { ToastContainer } from "../components/Toast"
import { NewsletterModal } from "../features/Newsletter"
import { useNavigator } from "../hooks"

export default function Guest(): React.JSX.Element {
  const [dark, setDark] = React.useState(false)
  const { pathname } = useLocation()
  const { isInternalCrawler } = useNavigator()
  const homepage = pathname === "/"

  React.useEffect(() => {
    setDark(pathname === "/" && !isInternalCrawler())
  }, [pathname, isInternalCrawler])

  return (
    <>
      <Header
        logoUrl="/"
        logoImage="/image/sta-icon.png"
        logoImageDark="/image/sta-icon-dark.png"
        linkItems={[
          {
            to: "/about-us",
            content: "About",
            items: [
              {
                to: "/about-us",
                content: "About Us",
              },
              {
                to: "/the-council",
                content: "Meet The Council",
              },
            ],
          },
          {
            to: "/membership",
            content: "Membership",
            items: [
              {
                to: "/membership",
                content: "Be A Member",
              },
              {
                to: "/membership#stc",
                content: "Standard Trading Conditions",
              },
              {
                to: "/members-directory",
                content: "Members Directory",
              },
              {
                to: "/membership-application",
                content: "Membership Application",
              },
            ]
          },
          {
            to: "/news-events",
            content: "News & Events",
          },
          {
            to: "/contact-us",
            content: "Contact",
          },
        ]}
        dark={dark}
        fixed={homepage}
        sticky={!homepage} />
      <ToastContainer />
      <div className="font-inter min-h-screen">
        <Outlet />
      </div>
      <Footer
        logoUrl="/"
        logoImage="/image/sta-logo.png"
        contactUrl="/contact-us"
        contactItems={[
          <>
            <div className="flex flex-row gap-2 justify-start items-start">
              <div>
                <IoLocationOutline className="block h-6 w-6 text-sta-primary" aria-hidden="true" />
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-sm md:text-lg text-black">
                  <Link to="https://maps.app.goo.gl/8u4rCAbm5KjSC6oS7" target="_blank" hideCrawl
                    className="text-sm md:text-lg text-black hover:text-sta-primary">
                    9 Jurong Town Hall Road,
                    #03-36 Trade Association Hub,
                    Singapore 609431
                  </Link>
                </p>
              </div>
            </div>
          </>,
          <>
            <div className="flex flex-row gap-2 justify-start items-start">
              <div>
                <IoCallOutline className="block h-6 w-6 text-sta-primary" aria-hidden="true" />
              </div>
              <div className="flex flex-col">
                <Link to="tel:+6568420853" target="_blank" hideCrawl
                  className="text-sm md:text-lg text-black hover:text-sta-primary">
                  (+65) 6842 0853
                </Link>
                <Link to="tel:+6581555987" target="_blank" hideCrawl
                  className="text-sm md:text-lg text-black hover:text-sta-primary">
                  (+65) 8155 5987
                </Link>
              </div>
            </div>
          </>,
          <>
            <div className="flex flex-row gap-2 justify-start items-start">
              <div>
                <PiWhatsappLogo className="block h-6 w-6 text-sta-primary" aria-hidden="true" />
              </div>
              <div className="flex flex-col">
                <Link to="https://wa.me/message/ALON3ZUL5XFDL1" target="_blank" hideCrawl
                  className="text-sm md:text-lg text-black hover:text-sta-primary">
                  (+65) 8815 5987
                </Link>
              </div>
            </div>
          </>,
          <>
            <div className="flex flex-row gap-2 justify-start items-start">
              <div>
                <IoAtOutline className="block h-6 w-6 text-sta-primary" aria-hidden="true" />
              </div>
              <div className="flex flex-col gap-2">
                <Link to="mailto:admin@sta.sg" target="_blank" hideCrawl
                  className="text-sm md:text-lg text-black hover:text-sta-primary">
                  admin@sta.sg
                </Link>
              </div>
            </div>
          </>,
        ]}
        menuItems={[
          {
            heading: "Get To Know Us",
            headingUrl: "/about-us",
            items: [
              {
                to: "/about-us",
                content: "About Us",
              },
              {
                to: "/the-council",
                content: "The Council",
              },
              {
                to: "/news-events",
                content: "News & Events",
              },
            ],
          },
          {
            heading: "Be A Member",
            headingUrl: "/membership",
            items: [
              {
                to: "/membership",
                content: "Membership",
              },
              {
                to: "/membership#stc",
                content: "Standard Trading Conditions",
              },
              {
                to: "/members-directory",
                content: "Members Directory",
              },
              {
                to: "/membership-application",
                content: "Membership Application",
              },
            ],
          },
        ]}
        mediaItems={[
          {
            to: "https://www.linkedin.com/company/singapore-transport-association",
            target: "_blank",
            hideCrawl: true,
            content: (
              <RiLinkedinFill className="block h-6 w-6" aria-hidden="true" />
            )
          },
          {
            to: "https://www.facebook.com/people/Singapore-Transport-Association/100090536295667/",
            target: "_blank",
            hideCrawl: true,
            content: (
              <RiFacebookFill className="block h-6 w-6" aria-hidden="true" />
            )
          },
          {
            to: "https://www.instagram.com/sg.transport.association/",
            target: "_blank",
            hideCrawl: true,
            content: (
              <RiInstagramFill className="block h-6 w-6" aria-hidden="true" />
            )
          },
        ]}
        linkItems={[
          {
            to: "/p/privacy-policy",
            content: "Privacy Policy",
          },
          {
            to: "/p/terms-conditions",
            content: "Terms & Conditions",
          }
        ]}
      />

      <NewsletterModal
        portalId="overlay-root"
        delayTimer={7000}
        maxShow={1}
        logoImage="/image/sta-logo.png" />
    </>
  )
}