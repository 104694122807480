import * as React from "react"
import { FiFrown } from "react-icons/fi"

type InfoProps = {
  children?: React.ReactNode
  title?: string
  subtitle?: string
}

export function Info(props: InfoProps): React.JSX.Element {
  const {
    title = "Oops, there is no information available at the moment",
    subtitle = "Please come back again later",
  } = props

  return (
    <div className="w-full max-w-[500px] mx-auto">
      <div className="flex flex-col justify-center items-center text-center gap-2">
        <FiFrown className="w-14 h-14" aria-hidden="true" />
        <p className="font-bold text-lg md:text-2xl text-sta-primary">
          {title}
        </p>
        <p className="text-base md:text-lg"
          dangerouslySetInnerHTML={{ __html: subtitle }}>
        </p>
      </div>
    </div>
  )
}