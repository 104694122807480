import * as React from "react"
import { useSearchParams, To } from "react-router-dom"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6"
import { Link } from "../Router"

type PaginationProps = {
  currentPage?: number
  itemPerPage?: number
  pageRange?: number
  totalItems?: number
  to?: To
  onSelected?: ({ page }: { page: number }) => void
}

export function Pagination(props: PaginationProps): React.JSX.Element {
  const {
    currentPage = 1,
    itemPerPage = 12,
    pageRange = 5,
    totalItems = 0,
    to,
    onSelected = () => { },
  } = props

  const [searchParams] = useSearchParams()
  const params = generateQueryStrings(searchParams, ["page"])

  const totalPages = Math.ceil(totalItems / itemPerPage)

  function getPageRange() {
    let startPage = Math.max(1, currentPage - Math.floor(pageRange / 2))
    let endPage = startPage + pageRange - 1
    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(1, endPage - pageRange + 1)
    }
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    )
  }

  function selectPage(p: number) {
    onSelected && onSelected({
      page: p
    })
  }

  return (
    <div className="flex flex-row flex-wrap justify-center items-center gap-3">
      <ButtonNavigation
        to={to + `${currentPage - 1}` + params}
        onClick={() => { selectPage(currentPage - 1) }}
        appendClassNames="flex flex-row gap-1 justify-center items-center"
        disabled={currentPage <= 1}>
        <FaChevronLeft className="w-3 h-3" aria-hidden="true" />
        <span>Previous</span>
      </ButtonNavigation>
      {
        getPageRange().map((page: number, i: number) => {
          return (
            <React.Fragment key={`pagination-item-${i}`}>
              <ButtonNavigation
                to={to + `${page}` + params}
                onClick={() => { selectPage(page) }}
                active={page === currentPage}>
                {page}
              </ButtonNavigation>
            </React.Fragment>
          )
        })
      }
      <ButtonNavigation
        to={to + `${currentPage + 1}` + params}
        onClick={() => { selectPage(currentPage + 1) }}
        appendClassNames="flex flex-row gap-1 justify-center items-center"
        disabled={currentPage >= totalPages}>
        <span>Next</span>
        <FaChevronRight className="w-3 h-3" aria-hidden="true" />
      </ButtonNavigation>
    </div>
  )
}

function generateQueryStrings(params: URLSearchParams, exceptions: string[]): string {
  const reserved = new Set<string>(exceptions)
  let url = ""
  for (const [key, value] of Array.from(params)) {
    if (reserved.has(key)) {
      continue
    }
    url += `&${key}=${value}`
  }
  return url
}

type ButtonNavigationProps = {
  children?: React.ReactNode
  to?: To
  onClick: () => void
  active?: boolean
  disabled?: boolean
  appendClassNames?: string
}

function ButtonNavigation(props: ButtonNavigationProps): React.JSX.Element {
  const {
    to,
    onClick,
    active = false,
    disabled = false,
    appendClassNames = "",
  } = props

  return (
    <>
      {
        to ?
          <>
            {
              disabled ?
                <span className={
                  "text-sm md:text-lg text-sta-gray cursor-not-allowed " +
                  appendClassNames
                }>
                  {props.children}
                </span>
                :
                <Link to={to} className={
                  "text-sm md:text-lg hover:text-sta-primary disabled:text-sta-gray disabled:cursor-not-allowed " +
                  (active ? "text-sta-primary font-semibold " : "text-black ") +
                  appendClassNames
                }>
                  {props.children}
                </Link>
            }
          </>
          :
          <button onClick={onClick}
            className={
              "text-sm md:text-lg hover:text-sta-primary disabled:text-sta-gray disabled:cursor-not-allowed " +
              (active ? "text-sta-primary font-semibold " : "text-black ") +
              appendClassNames
            }
            disabled={disabled}>
            {props.children}
          </button>
      }
    </>
  )
}
