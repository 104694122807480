import * as React from "react"
import { Popover } from "@headlessui/react"

type HeaderProps = {
  children?: React.ReactNode
  appendClassNames?: string
  sticky?: boolean
  fixed?: boolean
  props?: Record<string, unknown>
}

const Base = React.forwardRef<HTMLAnchorElement, HeaderProps>(function (props, ref): React.JSX.Element {
  const {
    sticky = false,
    fixed = false,
    appendClassNames = "",
  } = props

  return (
    <>
      <Popover.Group as="header"
        className={
          (sticky ? "sticky " : "") +
          (fixed ? "fixed " : "") +
          (sticky || fixed ? "top-0 left-0 z-10 " : "relative ") +
          appendClassNames
        }
        ref={ref}
        {...props.props}>
        {props.children}
      </Popover.Group>
      {
        fixed &&
        <div className={(fixed ? "mt-[65px] xl:mt-[100px]" : "")} />
      }
    </>
  )
})

export {
  Base
}