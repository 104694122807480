import * as React from "react"
import { Container } from "../../components/Container"
import { Newsletter } from "./Newsletter"

export function NewsletterSection(): React.JSX.Element {
  return (
    <div aria-label="newsletter"> {/* @note: do not remove to avoid newlseter section contain unwanted element attributes */}
      <div className="bg-sta-cloud py-10 md:py-20 w-full h-full flex justify-center items-center min-h-[350px]">
        <Container size="md">
          <Newsletter />
        </Container>
      </div>
    </div>
  )
}