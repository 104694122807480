import * as React from "react"
import ReactSelect from "react-select"

export enum SelectSize {
  LARGE = "lg",
  MEDIUM = "md"
}

type SelectSizeProps = "lg" | "md"

const themes = new Map<string, Map<string, string>>()

const staTheme = new Map<string, string>()
staTheme.set("container", "")
staTheme.set("control", "block w-full shadow-sm")
staTheme.set("control-isopen-true", "rounded-t-2xl bg-white border-1 border-white")
staTheme.set("control-isopen-false", "rounded-[45px] md:rounded-[55px] bg-sta-secondary border-1 border-sta-secondary")
staTheme.set("option", "")
staTheme.set("option-isselected-true", "bg-sta-secondary")
staTheme.set("option-isselected-false", "bg-white")
staTheme.set("option-islast-true", "rounded-b-2xl")
staTheme.set("option-islast-false", "")
staTheme.set("empty", "rounded-b-2xl bg-white")
staTheme.set(`size-${SelectSize.LARGE}`, "px-6 py-3")
staTheme.set(`size-${SelectSize.MEDIUM}`, "px-5 py-2")
staTheme.set("disabled-true", "cursor-not-allowed")

themes.set("sta", staTheme)

class SelectStyle {
  private theme: string = ""
  private disabled: boolean = false
  private size: string = ""

  public buildContainer(): string {
    let style = ""

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("container")) {
      style += theme.get("container")
    }

    if (theme.has(`disabled-${this.disabled}`)) {
      style += " " + theme.get(`disabled-${this.disabled}`)
    }

    return style
  }

  public buildControl({ isOpen }: { isOpen: boolean }): string {
    let style = ""

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("control")) {
      style += theme.get("control")
    }

    if (theme.has(`size-${this.size}`)) {
      style += " " + theme.get(`size-${this.size}`)
    }

    if (theme.has(`control-isopen-${isOpen}`)) {
      style += " " + theme.get(`control-isopen-${isOpen}`)
    }

    return style
  }

  public buildOption({ isSelected, isLast }: { isSelected: boolean, isLast: boolean }): string {
    let style = ""

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("option")) {
      style += theme.get("option")
    }

    if (theme.has(`size-${this.size}`)) {
      style += " " + theme.get(`size-${this.size}`)
    }

    if (theme.has(`option-isselected-${isSelected}`)) {
      style += " " + theme.get(`option-isselected-${isSelected}`)
    }

    if (theme.has(`option-islast-${isLast}`)) {
      style += " " + theme.get(`option-islast-${isLast}`)
    }

    return style
  }

  public buildEmpty(): string {
    let style = ""

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("empty")) {
      style += theme.get("empty")
    }

    if (theme.has(`size-${this.size}`)) {
      style += " " + theme.get(`size-${this.size}`)
    }

    return style
  }

  public setTheme(theme: string): SelectStyle {
    this.theme = theme
    return this
  }

  public setDisabled(disabled: boolean): SelectStyle {
    this.disabled = disabled
    return this
  }

  public setSize(size: string): SelectStyle {
    this.size = size
    return this
  }
}

const styleBuilder = new SelectStyle()

type SelectProps = {
  id?: string
  name?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  searchable?: boolean
  value?: OptionItem
  appendClassNames?: string
  size?: SelectSizeProps
  options?: OptionItem[]
  onUpdate?: (m: UpdateMessage) => void
}

type UpdateMessage = {
  value: string
  option: OptionItem
}

type OptionItem = {
  value: string
  label?: string
}

export function Select(props: SelectProps): React.JSX.Element {
  const {
    disabled = false,
    required = false,
    searchable = false,
    options = [],
    appendClassNames = "",
    placeholder = "Choose",
    size = SelectSize.MEDIUM,
    onUpdate = () => { },
  } = props

  const handleChange = (newValue: any) => {
    onUpdate && onUpdate({
      value: newValue.value,
      option: newValue
    })
  }

  const [items, setItems] = React.useState([])

  React.useEffect(() => {
    setItems(options.map((option, i: number) => {
      return {
        value: option.value,
        label: option.label !== undefined ? option.label : option.value,
        index: i
      }
    }))
  }, [options])

  styleBuilder
    .setDisabled(disabled)
    .setSize(size)
    .setTheme("sta")

  const containerClassNames = styleBuilder.buildContainer()

  return (
    <ReactSelect
      id={props.id}
      name={props.name}
      placeholder={placeholder}
      isDisabled={disabled}
      required={required}
      isSearchable={searchable}
      value={props.value}
      onChange={handleChange}
      classNames={{
        container: () => {
          return appendClassNames + " " + containerClassNames
        },
        control: (state) => {
          return styleBuilder.buildControl({ isOpen: state.menuIsOpen })
        },
        option: (state) => {
          const data = state.data as unknown as { value: string, label: string, index: number }
          const isLast = data.index === (items.length - 1)

          return styleBuilder.buildOption({
            isSelected: state.isSelected,
            isLast
          })
        },
        noOptionsMessage: () => {
          return styleBuilder.buildEmpty()
        }
      }}
      options={items}
      unstyled>

    </ReactSelect>
  )
}