import * as React from "react"
import { IoLocationOutline } from "react-icons/io5"
import { Container } from "../components/Container"
import { Loader } from "../components/Loader"
import { MapViewer } from "../components/MapViewer"
import { Meta } from "../components/Meta"
import { NewsletterSection } from "../features/Newsletter"
import { useAutoPosition, usePage } from "../hooks"
import { Field } from "../services/Field"

const contactDefaultIcon = "https://placehold.co/24x24.jpg?text=I&font=roboto"

export default function Contact(): React.JSX.Element {
  const [page] = usePage({
    slug: "contact-us"
  })
  const [contact, setContact] = React.useState({
    items: []
  })

  React.useEffect(() => {
    const defaultContactItems = [
      {
        title: "Email",
        description: "We'll respond within 1-2 working days",
        content: `<p><a href="mailto:admin@sta.sg">admin@sta.sg</a></p>`,
        icon: `/image/contact-email.svg`
      },
      {
        title: "Phone",
        description: "Mon to Fri, from 10am to 6pm (except public holiday)",
        content: `<ul><li><a href="tel:+6568420853">(+65) 6842 0853</a></li><li><a href="tel:+6581555987">(+65) 8155 5987</a></li></ul>`,
        icon: `/image/contact-phone.svg`
      },
      {
        title: "Whatsapp",
        description: "Mon to Fri, from 10am to 6pm (except public holiday)",
        content: `<p><a href="https://wa.me/message/ALON3ZUL5XFDL1">(+65) 8815 5987</a></p>`,
        icon: `/image/contact-whatsapp.svg`
      }
    ].map((item) => {
      return new Field([
        {
          key: "title",
          type: "text",
          text_value: item.title,
        },
        {
          key: "description",
          type: "text",
          text_value: item.description,
        },
        {
          key: "content",
          type: "text",
          text_value: item.content,
        },
        {
          key: "icon",
          type: "file",
          file_value: item.icon,
        }
      ])
    })
    const contactItems = page.detail.fields.collectionVal("CONTACT_ITEMS", defaultContactItems)

    setContact((prevState) => {
      return {
        ...prevState,
        items: contactItems.map((contact) => {
          return {
            title: contact.textVal("title"),
            description: contact.textVal("description"),
            content: contact.textVal("content"),
            icon: contact.tryTextVal("icon", contactDefaultIcon),
          }
        }),
      }
    })
  }, [page])

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: `${page.detail.title || "Contact Us"}`,
          description: `Contact us for further enquiries`,
        }}
      </Meta>

      <div className="w-full h-[250px] md:h-[400px] bg-sta-primary">
        <div className="w-full h-full flex justify-start items-center">
          <Container size="md">
            <p className="font-bold text-2xl md:text-5xl text-white my-10 xl:my-0">
              {page.detail.title || "Contact Us"}
            </p>
          </Container>
        </div>
      </div>

      <Container size="md">
        <div className="py-10 md:py-20">
          <div className="grid grid-cols-12 gap-4 md:gap-8 mb-5 md:mb-10">
            {
              contact.items.map((contact, i: number) => {
                return (
                  <React.Fragment key={`contact-item-${i}`}>
                    <div className="col-span-12 lg:col-span-4">
                      <div className="bg-sta-navy rounded-3xl p-5 lg:p-9 h-full min-h-[140px] lg:min-h-[290px] shadow-xl">
                        <div className="flex flex-col justify-center items-between gap-10 lg:gap-20">
                          <img alt="contact" src={contact.icon} className="w-10 h-10 text-sta-secondary" />
                          <div className="flex flex-col gap-2">
                            <p className="font-bold text-sta-secondary text-base lg:text-2xl uppercase">
                              {contact.title}
                            </p>
                            <p className="font-light text-sta-cloud text-xs lg:text-base">
                              {contact.description}
                            </p>
                            <div className="html-viewer flex flex-col gap-2 my-4 lg:mt-8 font-semibold text-white text-base lg:text-lg"
                              dangerouslySetInnerHTML={{
                                __html: contact.content
                              }}>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })
            }
          </div>
          {
            page.detail.fields.boolVal("LOCATION_VISIBILITY") &&
            <div className="grid grid-cols-12">
              <div className="col-span-12 lg:col-span-4">
                <div className="bg-sta-navy rounded-t-3xl lg:rounded-r-none lg:rounded-l-3xl p-5 lg:p-9 h-full min-h-[140px] lg:min-h-[290px] shadow-xl">
                  <div className="flex flex-col justify-center items-between gap-10 lg:gap-20">
                    <IoLocationOutline className="w-10 h-10 text-sta-secondary" aria-hidden="true" />
                    <div className="flex flex-col gap-2">
                      <p className="font-bold text-sta-secondary text-base lg:text-2xl uppercase">
                        {page.detail.fields.textVal("LOCATION_TITLE_TEXT", `Location`)}
                      </p>
                      <p className="font-light text-sta-cloud text-xs lg:text-base">
                        {page.detail.fields.textVal("LOCATION_DESCRIPTION_TEXT", `Opening Hours 9am - 6pm`)}
                      </p>
                      <div className="html-viewer flex flex-col gap-2 my-4 lg:mt-8 font-semibold text-white text-base lg:text-lg"
                        dangerouslySetInnerHTML={{
                          __html: page.detail.fields.textVal(
                            "LOCATION_CONTENT_TEXT",
                            `<p><a href="https://maps.app.goo.gl/8u4rCAbm5KjSC6oS7">9 Jurong Town Hall Road, #03-36 Trade Association Hub, Singapore 609431</a></p>`
                          )
                        }}>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-8">
                <Loader lazy>
                  <MapViewer appendClassNames="rounded-b-3xl lg:rounded-l-none lg:rounded-r-3xl h-full min-h-[290px] shadow-xl"
                    provider="gmap_embed" title="location"
                    query={page.detail.fields.textVal("LOCATION_MAP_QUERY", `Singapore+Transport+Association,9+Jurong+Town+Hall+Rd,+Singapore+609431`)} />
                </Loader>
              </div>
            </div>
          }

        </div>
      </Container>

      <NewsletterSection />
    </>
  )
}