import * as React from "react"

type SkeletonProps = {
  width?: string
  height?: string
  children?: React.ReactNode
  appendClassNames?: string
  rounded?: boolean
}

export function Skeleton(props: SkeletonProps): React.JSX.Element {
  const {
    width,
    height,
    appendClassNames = "",
    rounded = false,
  } = props

  return (
    <div className={
      "animate-pulse bg-slate-200 " +
      (rounded ? "rounded-xl " : " ") + appendClassNames
    }
      style={{ width, height }}>
      {props.children ? props.children : <>&nbsp;</>}
    </div>
  )
}