import * as React from "react"
import { FaRegCalendar } from "react-icons/fa6"
import { DateInput } from "@mantine/dates"
import {
  InputSize,
  InputSizeProps
} from "./Resource"

const themes = new Map<string, Map<string, string>>()

const defaultTheme = new Map<string, string>()
defaultTheme.set("container", "w-full border rounded outline outline-1 outline-slate-100")
defaultTheme.set("element", "min-h-0 h-full !pl-8 text-base")
defaultTheme.set("icon", "w-6")
defaultTheme.set("disabled-true", "cursor-not-allowed")
defaultTheme.set(`size-${InputSize.LARGE}`, "px-2 py-3")
defaultTheme.set(`size-${InputSize.MEDIUM}`, "p-2")

const staTheme = new Map<string, string>()
staTheme.set("container", "w-full bg-sta-cloud border-1 md:border-2 border-sta-primary rounded outline outline-1 outline-slate-100")
staTheme.set("element", "min-h-0 h-full !pl-8 text-base")
staTheme.set("icon", "w-6")
staTheme.set("disabled-true", "cursor-not-allowed")
staTheme.set(`size-${InputSize.LARGE}`, "px-2 py-3")
staTheme.set(`size-${InputSize.MEDIUM}`, "p-2")

themes.set("", defaultTheme)
themes.set("sta", staTheme)

class InputDateStyle {

  private theme: string = ""
  private disabled: boolean = false
  private size: string = ""
  private appendedClassNames: string | undefined = ""

  public buildElement(): string | undefined {
    let style: string | undefined = undefined

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("element")) {
      style = theme.get("element")
    }

    return style
  }

  public buildContainer(): string | undefined {
    let style: string | undefined = undefined

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("container")) {
      style = theme.get("container")
    }

    if (theme.has(`size-${this.size}`)) {
      style += " " + theme.get(`size-${this.size}`)
    }

    if (theme.has(`disabled-${this.disabled}`)) {
      style += " " + theme.get(`disabled-${this.disabled}`)
    }

    if (this.appendedClassNames && this.appendedClassNames !== "") {
      style += " " + this.appendedClassNames
    }

    return style
  }

  public buildIcon(): string | undefined {
    let style: string | undefined = undefined

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("icon")) {
      style = theme.get("icon")
    }

    return style
  }

  public setTheme(theme: string): InputDateStyle {
    this.theme = theme
    return this
  }

  public setDisabled(disabled: boolean): InputDateStyle {
    this.disabled = disabled
    return this
  }

  public setSize(size: string): InputDateStyle {
    this.size = size
    return this
  }

  public setAppendedClassNames(appendedClassNames?: string): InputDateStyle {
    this.appendedClassNames = appendedClassNames
    return this
  }
}

type InputDateProps = {
  id?: string
  name?: string
  placeholder?: string
  value?: number | Date
  size?: InputSizeProps
  appendClassNames?: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
  onUpdate?: (p: UpdateMessage) => void
}

type UpdateMessage = {
  date: Date
}

const styleBuilder = new InputDateStyle()

export function InputDate(props: InputDateProps): React.JSX.Element {
  const {
    required = false,
    disabled = false,
    readOnly = false,
    size = InputSize.MEDIUM,
    appendClassNames,
  } = props

  styleBuilder
    .setAppendedClassNames(appendClassNames)
    .setDisabled(disabled)
    .setSize(size)
    .setTheme("sta")

  const containerClassNames = styleBuilder.buildContainer()
  const elementClassNames = styleBuilder.buildElement()
  const iconClassNames = styleBuilder.buildIcon()

  const value = props.value ?
    (typeof props.value === "number" ? new Date(props.value) : props.value) :
    undefined

  return (
    <DateInput
      id={props.id}
      name={props.name}
      disabled={disabled}
      readOnly={readOnly}
      clearable={!required}
      placeholder={props.placeholder}
      value={value}
      valueFormat={"DD/MM/YYYY"}
      onChange={(d: Date) => {
        props.onUpdate && props.onUpdate({
          date: d
        })
      }}
      onBlur={props.onBlur}
      icon={<FaRegCalendar className="w-4 h-4" aria-hidden="true" />}
      variant="unstyled"
      classNames={{
        root: containerClassNames,
        input: elementClassNames,
        icon: iconClassNames,
      }}
    />
  )
}