import * as React from "react"
import { To } from "react-router-dom"
import { Link } from "./../Router"

type TabProps = {
  children?: React.ReactNode
  appendClassNames?: string
}

export function Tab(props: TabProps): React.JSX.Element {
  return (
    <div role="tab" className={props.appendClassNames}>
      {props.children}
    </div>
  )
}

type TabMenuProps = {
  children?: React.ReactNode
  className?: string
}

export function TabMenu(props: TabMenuProps): React.JSX.Element {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  )
}

type TabButtonProps = {
  children?: React.ReactNode
  appendClassNames?: string
  to?: To
  active?: boolean
}

export function TabButton(props: TabButtonProps): React.JSX.Element {
  const {
    to,
    active = false,
    appendClassNames = "",
  } = props

  let className = `inline-block rounded-[55px] py-2.5 px-8 ease-out duration-500`

  if (active) {
    className += ` bg-sta-secondary hover:bg-white text-black border-1 border-sta-secondary`
  } else {
    className += ` bg-transparent hover:bg-sta-secondary text-white hover:text-black border-1 border-transparent hover:border-sta-secondary`
  }

  className += ` ${appendClassNames}`

  return (
    <>
      {
        to ?
          <Link to={to} className={className}>
            {props.children}
          </Link>
          :
          <button className={className}>
            {props.children}
          </button>
      }
    </>
  )
}