import * as React from "react"
import { Base } from "./Base"
import styles from "./InputNumber.module.css"
import {
  InputSize,
  InputSizeProps
} from "./Resource"

const themes = new Map<string, Map<string, string>>()

const defaultTheme = new Map<string, string>()
defaultTheme.set("", styles['input-number'] + " w-full border rounded outline outline-1 outline-slate-100")
defaultTheme.set("disabled-true", "cursor-not-allowed")
defaultTheme.set(`size-${InputSize.LARGE}`, "px-2 py-3")
defaultTheme.set(`size-${InputSize.MEDIUM}`, "p-2")

const staTheme = new Map<string, string>()
staTheme.set("", styles['input-number'] + " w-full bg-sta-cloud border-1 md:border-2 border-sta-primary rounded outline outline-1 outline-slate-100")
staTheme.set("disabled-true", "cursor-not-allowed")
staTheme.set(`size-${InputSize.LARGE}`, "px-2 py-3")
staTheme.set(`size-${InputSize.MEDIUM}`, "p-2")

themes.set("", defaultTheme)
themes.set("sta", staTheme)

class InputNumberStyle {

  private theme: string = ""
  private disabled: boolean = false
  private size: string = ""
  private elementClassNames: string | undefined = ""
  private appendedClassNames: string | undefined = ""

  public buildElement(): string | undefined {
    let style: string | undefined = undefined

    if (this.elementClassNames) {
      return this.elementClassNames
    }

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("")) {
      style = theme.get("")
    }

    if (theme.has(`disabled-${this.disabled}`)) {
      style += " " + theme.get(`disabled-${this.disabled}`)
    }

    if (theme.has(`size-${this.size}`)) {
      style += " " + theme.get(`size-${this.size}`)
    }

    if (this.appendedClassNames && this.appendedClassNames !== "") {
      style += " " + this.appendedClassNames
    }

    return style
  }

  public setTheme(theme: string): InputNumberStyle {
    this.theme = theme
    return this
  }

  public setDisabled(disabled: boolean): InputNumberStyle {
    this.disabled = disabled
    return this
  }

  public setSize(size: string): InputNumberStyle {
    this.size = size
    return this
  }

  public setElementClassNames(elementClassNames?: string): InputNumberStyle {
    this.elementClassNames = elementClassNames
    return this
  }

  public setAppendedClassNames(appendedClassNames?: string): InputNumberStyle {
    this.appendedClassNames = appendedClassNames
    return this
  }
}

type InputNumberProps = {
  id?: string
  name?: string
  placeholder?: string
  defaultValue?: string | number | ReadonlyArray<string> | undefined
  value?: string | ReadonlyArray<string> | number | undefined
  style?: React.CSSProperties | undefined
  size?: InputSizeProps
  className?: string
  appendClassNames?: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
}

const styleBuilder = new InputNumberStyle()

export function InputNumber(props: InputNumberProps): React.JSX.Element {
  const {
    disabled = false,
    size = InputSize.MEDIUM,
    appendClassNames,
    className,
    style,
  } = props

  styleBuilder
    .setElementClassNames(className)
    .setAppendedClassNames(appendClassNames)
    .setDisabled(disabled)
    .setSize(size)
    .setTheme("sta")

  const elementClassNames = styleBuilder.buildElement()

  return (
    <Base
      type="number"
      className={elementClassNames}
      style={style}
      {...props} />
  )
}