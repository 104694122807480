import * as React from "react"
import { Error } from "../components/Page"
import { Meta } from "../components/Meta"

export default function NotFound(): React.JSX.Element {
  return (
    <>
      <Meta>
        {{
          title: "404 Page not found"
        }}
      </Meta>

      <Error title="404" subtitle="Page not found" />
    </>
  )
}