
export function useNavigator() {

  function hasNavigator(): boolean {
    return navigator !== undefined && navigator !== null 
  }

  function isAgent(agent: string): boolean {
    if (!hasNavigator()) {
      return false
    }
    return agent === navigator.userAgent
  }

  /**
   * @description 
   * return true when `userAgent` is internal web crawler (e.g: ReactSnap)
   * @returns boolean
   */
  function isInternalCrawler(): boolean {
    if (!hasNavigator()) {
      return false
    }
    return navigator.userAgent === "ReactSnap"
  }

  return {
    isAgent,
    isInternalCrawler
  }
}
