
export enum FieldNotifVariant {
  DANGER = "danger",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success"
}

export type FieldNotifVariantProps = "danger" | "warning" | "info" | "success"

export class FieldNotifStyle {
  private type: string = ""

  public build(): string {
    let style = ""

    if (this.type === FieldNotifVariant.DANGER) {
      style += "text-sm text-red-600"
    } else if (this.type === FieldNotifVariant.WARNING) {
      style += "text-sm text-yellow-600"
    } else if (this.type === FieldNotifVariant.INFO) {
      style += "text-sm text-blue-600"
    } else if (this.type === FieldNotifVariant.SUCCESS) {
      style += "text-sm text-green-600"
    }

    return style
  }

  setVariant(type: string): FieldNotifStyle {
    this.type = type
    return this
  }
}
