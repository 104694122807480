import * as React from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Container } from "../components/Container"
import { Error } from "../components/Page"
import { Meta } from "../components/Meta"
import { Skeleton } from "../components/Skeleton"
import { NewsletterSection } from "../features/Newsletter"
import { StatusCode } from "../services/Status"
import { useAutoPosition, useHashFragment, usePage } from "../hooks"

export default function PageDetail(): React.JSX.Element {
  const { slug } = useParams()
  const [page] = usePage({
    slug
  })
  const navigate = useNavigate()

  React.useEffect(() => {
    if (page.loading) {
      return
    }

    const slug = page.detail.slug === "home" ? "" : page.detail.slug
    const mode = page.detail.mode
    if (mode === "static") {
      navigate(`/${slug}`)
    }
  }, [page, navigate])

  useAutoPosition()
  useHashFragment()

  return (
    <>
      {
        page.error &&
        <>
          <Meta>
            {{
              title: (page.error.Is(StatusCode.RESOURCE_NOTFOUND) ? `404 Page not found` : "Oops, unexpected error happened"),
              description: (page.error.Is(StatusCode.RESOURCE_NOTFOUND) ? `404 Page not found` : page.error.message),
            }}
          </Meta>

          <Error
            title={page.error.Is(StatusCode.RESOURCE_NOTFOUND) ? "404" : `${page.error.code}`}
            subtitle={page.error.Is(StatusCode.RESOURCE_NOTFOUND) ? "Oops, page not found!" : page.error.message} />
        </>
      }
      {
        !page.error &&
        <>
          <Meta>
            {{
              title: `${page.detail.title || "Page Detail"}`,
              description: `${page.detail.title || "Page Detail"}`,
            }}
          </Meta>

          <div className="w-full h-[250px] md:h-[400px] bg-sta-primary">
            <div className="w-full h-full flex justify-start items-center">
              <Container size="md">
                {
                  page.loading ?
                    <>
                      <Skeleton width="40%" height="20px" rounded />
                    </>
                    :
                    <p className="font-bold text-2xl md:text-5xl text-white my-10 xl:my-0">
                      {page.detail.title || "Page Detail"}
                    </p>
                }
              </Container>
            </div>
          </div>

          <Container size="md">
            <div className="py-10 md:py-20">
              {
                page.loading &&
                <>
                  <div className="flex flex-col gap-2">
                    <Skeleton width="50%" height="20px" rounded />
                    <Skeleton width="50%" height="20px" rounded />
                    <Skeleton width="100%" height="10px" rounded />
                    <Skeleton width="100%" height="10px" rounded />
                    <Skeleton width="100%" height="10px" rounded />
                    <Skeleton width="100%" height="10px" rounded />
                    <Skeleton width="100%" height="10px" rounded />
                    <Skeleton width="100%" height="10px" rounded />
                    <Skeleton width="100%" height="10px" rounded />
                    <Skeleton width="100%" height="10px" rounded />
                    <Skeleton width="100%" height="10px" rounded />
                    <Skeleton width="100%" height="10px" rounded />
                    <Skeleton width="100%" height="10px" rounded />
                    <Skeleton width="100%" height="10px" rounded />
                    <Skeleton width="100%" height="10px" rounded />
                  </div>
                </>
              }

              {
                !page.loading &&
                <div className={"html-viewer leading-7"}
                  dangerouslySetInnerHTML={{
                    __html: page.detail.content
                  }}>

                </div>
              }
            </div>
          </Container>

          <NewsletterSection />
        </>
      }
    </>
  )
}