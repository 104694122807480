import * as React from "react"
import { HiChevronDown, HiChevronUp } from "react-icons/hi2"
import { AccordionSize, AccordionSizeProps } from "./Resource"

type AccordionProps = {
  children?: {
    items: ChildrenItem[]
  }
  size?: AccordionSizeProps
  alwaysOpen?: boolean
}

type ChildrenItem = {
  heading?: React.ReactNode
  content?: React.ReactNode
}

type Item = ChildrenItem & {
  opened: boolean
}

export function Accordion(props: AccordionProps): React.JSX.Element {
  const {
    size = AccordionSize.MEDIUM,
    alwaysOpen = false,
    children = {
      items: []
    }
  } = props

  const [items, setItems] = React.useState<Item[]>([])

  React.useEffect(() => {
    if (!children || !children.items) {
      return
    }

    setItems(children.items.map((item, i: number) => {
      return {
        ...item,
        opened: i === 0
      }
    }))
  }, [children])

  function toggleAccordion(p: number) {
    setItems((prevState) => {
      return prevState.map((item, i) => {
        if (alwaysOpen) {
          return {
            ...item,
            opened: i === p ? !item.opened : item.opened
          }
        }
        return {
          ...item,
          opened: i === p ? !item.opened : false
        }
      })
    })
  }

  return (
    <AccordionContainer size={size}>
      {
        items.map((item, i: number) => {
          return (
            <React.Fragment key={`accordion-item-${i}`}>
              <AccordionItem size={size} opened={item.opened}>
                <AccordionHeader
                  size={size} opened={item.opened}
                  onClick={() => {
                    toggleAccordion(i)
                  }}>
                  {item.heading}
                </AccordionHeader>
                <AccordionContent opened={item.opened}>
                  {item.content}
                </AccordionContent>
              </AccordionItem>
            </React.Fragment>
          )
        })
      }
    </AccordionContainer>
  )
}

type AccordionContainerProps = {
  children?: React.ReactNode
  size?: AccordionSizeProps
}

export function AccordionContainer(props: AccordionContainerProps): React.JSX.Element {
  return (
    <ul className="w-full h-full list-none flex flex-col gap-4">
      {props.children}
    </ul>
  )
}

type AccordionItemProps = {
  children?: React.ReactNode
  size?: AccordionSizeProps
  opened?: boolean
}

export function AccordionItem(props: AccordionItemProps): React.JSX.Element {
  const {
    opened = false,
  } = props

  return (
    <li className={"bg-white rounded-3xl p-5 flex flex-col " + (opened ? "gap-4" : "")}>
      {props.children}
    </li>
  )
}

type AccordionHeaderProps = {
  children?: React.ReactNode
  size?: AccordionSizeProps
  opened?: boolean
  onClick?: () => void
}

export function AccordionHeader(props: AccordionHeaderProps): React.JSX.Element {
  const {
    opened = false,
  } = props

  const handleClick = () => {
    props.onClick && props.onClick()
  }

  return (
    <div className="flex flex-row gap-2 justify-between items-center cursor-pointer"
      onClick={handleClick}>
      <div>
        {props.children}
      </div>
      <div>
        {
          opened ?
            <HiChevronDown className="h-5 w-5 ease-in-out duration-300 rotate-180 stroke-2 text-sta-primary" aria-hidden="true" />
            :
            <HiChevronUp className="h-5 w-5 ease-in-out duration-300 rotate-180 stroke-2 text-sta-primary" aria-hidden="true" />
        }
      </div>
    </div>
  )
}

type AccordionContentProps = {
  children?: React.ReactNode
  opened?: boolean
}

export function AccordionContent(props: AccordionContentProps): React.JSX.Element {
  const {
    opened = false,
  } = props

  return (
    <div>
      {
        opened ?
          props.children
          :
          null
      }
    </div>
  )
}