import {
  Status,
  ACTION_SUCCESS,
  UNEXPECTED_ERROR,
} from "../Status"
import {
  SearchArticleParam, SearchArticleResult,
  GetArticleParam, GetArticleResult,
  SearchEventParam, SearchEventResult,
  GetPageParam, GetPageResult
} from "../Blog"
import { Field } from "../Field"

export type BlogConfig = {
  host: string
}

export class Blog {

  constructor(private config: BlogConfig) { }

  public async SearchArticle(p?: SearchArticleParam): Promise<SearchArticleResult> {
    try {
      const {
        page,
        totalItems
      } = p || {}
      const url = `${this.config.host}/api/v1/articles/search`

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          page: page,
          total_items: totalItems,
        }),
        redirect: "follow",
      })

      if (response.status !== 200) {
        const error = await Status.FromResponse(response)
        return {
          error,
        }
      }

      const data = await response.json()

      return {
        success: new Status("success search articles", ACTION_SUCCESS),
        data: {
          summary: data.data.summary,
          items: data.data.items.map((item: any) => {
            return {
              ...item,
              thumbnailUrl: item.thumbnail_url,
              createdAt: item.created_at !== null ? new Date(item.created_at) : undefined,
            }
          }),
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async GetArticle(p: GetArticleParam): Promise<GetArticleResult> {
    try {
      const {
        slug
      } = p || {}
      const url = `${this.config.host}/api/v1/articles/slug/${slug}`

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        redirect: "follow",
      })

      if (response.status !== 200) {
        const error = await Status.FromResponse(response)
        return {
          error,
        }
      }

      const res = await response.json()
      const detail = res.data.detail

      return {
        success: new Status("success get article", ACTION_SUCCESS),
        data: {
          detail: {
            ...detail,
            thumbnailUrl: detail.thumbnail_url,
            attachmentUrls: detail.attachment_urls.map((url: string) => {
              return url
            }),
            collectionUrls: detail.collection_urls.map((url: string) => {
              return url
            }),
            createdAt: detail.created_at !== null ? new Date(detail.created_at) : undefined,
          }
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async SearchEvent(p?: SearchEventParam): Promise<SearchEventResult> {
    try {
      const {
        page,
        totalItems,
        mode,
      } = p || {}
      const url = `${this.config.host}/api/v1/events/search`

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          page: page,
          total_items: totalItems,
          mode: mode,
        }),
        redirect: "follow",
      })

      if (response.status !== 200) {
        const error = await Status.FromResponse(response)
        return {
          error,
        }
      }

      const data = await response.json()

      return {
        success: new Status("success search events", ACTION_SUCCESS),
        data: {
          summary: data.data.summary,
          items: data.data.items.map((item: any) => {
            return {
              ...item,
              thumbnailUrl: item.thumbnail_url || undefined,
              registrationLink: item.registration_link || undefined,
              createdAt: item.created_at !== null ? new Date(item.created_at) : undefined,
              startedAt: item.started_at !== null ? new Date(item.started_at) : undefined,
              finishedAt: item.finished_at !== null ? new Date(item.finished_at) : undefined,
            }
          }),
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async GetPage(p: GetPageParam): Promise<GetPageResult> {
    try {
      const {
        slug
      } = p || {}
      const url = `${this.config.host}/api/v1/pages/slug/${slug}`

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        redirect: "follow",
      })

      if (response.status !== 200) {
        const error = await Status.FromResponse(response)
        return {
          error,
        }
      }

      const res = await response.json()

      return {
        success: new Status("success get page", ACTION_SUCCESS),
        data: {
          detail: {
            ...res.data.detail,
            fields: new Field(res.data.detail.fields)
          }
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

}
