
export enum CarouselVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  INFO = "info"
}

export type CarouselVariantProps = "primary" | "secondary" | "info"

export enum NavigationPosition {
  TOPRIGHT = "tr",
  TOPLEFT = "tl",
  MIDDLE = "mid",
}

export type NavigationPositionProps = "tr" | "tl" | "mid"

export enum NavigationVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  INFO = "info"
}

export type NavigationVariantProps = "primary" | "secondary" | "info"

const themes = new Map<string, Map<string, string>>()

const midTheme = new Map<string, string>()
midTheme.set("container", "")
midTheme.set("button", "absolute z-[5] top-1/2 w-9 h-9 flex justify-center items-center disabled:text-sta-gray")
midTheme.set("icon", "w-6 md:w-9 h-6 md:h-9")
midTheme.set(`variant-${NavigationVariant.PRIMARY}`, "text-sta-primary hover:text-slate-600")
midTheme.set(`variant-${NavigationVariant.SECONDARY}`, "text-sta-secondary hover:text-slate-600")
midTheme.set(`variant-${NavigationVariant.INFO}`, "text-sta-navy hover:text-slate-600")

const topTheme = new Map<string, string>()
topTheme.set("container", "flex flex-row gap-4 mb-4 items-center")
topTheme.set("button", "w-9 h-9 flex justify-center items-center rounded-full disabled:bg-sta-gray disabled:border-sta-gray disabled:text-white")
topTheme.set("icon", "w-4 h-4")
topTheme.set(`variant-${NavigationVariant.PRIMARY}`, "bg-sta-primary hover:bg-white text-white hover:text-sta-primary border-1 border-sta-primary")
topTheme.set(`variant-${NavigationVariant.SECONDARY}`, "bg-sta-secondary hover:bg-white text-black hover:text-sta-secondary border-1 border-sta-secondary")
topTheme.set(`variant-${NavigationVariant.INFO}`, "bg-sta-navy hover:bg-white text-white hover:text-sta-navy border-1 border-sta-navy")

themes.set("mid", midTheme)
themes.set("tr", topTheme)
themes.set("tl", topTheme)

export class NavigationStyle
{
  private variant: string = ""
  private position: string = ""

  public buildContainer(): string {
    let style = ""

    const theme = themes.get(this.position)
    if (!theme) {
      return style
    }

    if (theme.has("container")) {
      style += theme.get("container")
    }

    return style
  }

  public buildButton(): string {
    let style = ""

    const theme = themes.get(this.position)
    if (!theme) {
      return style
    }

    if (theme.has("button")) {
      style += theme.get("button")
    }

    if (theme.has(`variant-${this.variant}`)) {
      style += " " + theme.get(`variant-${this.variant}`)
    }

    return style
  }

  public buildIcon(): string {
    let style = ""

    const theme = themes.get(this.position)
    if (!theme) {
      return style
    }

    if (theme.has("icon")) {
      style += theme.get("icon")
    }

    return style
  }

  public setVariant(variant: string): NavigationStyle {
    this.variant = variant
    return this
  }

  public setPosition(position: string): NavigationStyle {
    this.position = position
    return this
  }

}
