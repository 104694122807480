import * as React from "react"
import { useScript } from "usehooks-ts"

type RecaptchaProps = {
  siteId: string
  loadDelay?: number
  showBadge?: boolean
}

interface Recaptcha {
  ready(): Promise<void>
  render(
    container: HTMLElement,
    config: {
      theme?: 'dark' | 'light'
      size?: 'compact' | 'normal'
    },
  ): void
  execute(sitekey: string, config: { action: string }): string
}

export function useRecaptcha(props?: RecaptchaProps) {
  const {
    siteId,
    loadDelay = 3500,
    showBadge = false,
  } = props || {
    siteId: "",
    loadDelay: 3500,
    showBadge: false,
  }

  const isMounted = React.useRef(false)
  const [load, setLoad] = React.useState(false)
  const [recaptcha, setRecaptcha] = React.useState<Recaptcha>(undefined)

  React.useEffect(() => {
    if (isBrowser && !showBadge) {
      injectStyle('.grecaptcha-badge { visibility: hidden; }')
    }
  }, [showBadge])

  React.useEffect(() => {
    isMounted.current = true

    if ((window as any).grecaptcha) {
      (window as any).grecaptcha.ready(() => {
        setRecaptcha((window as any).grecaptcha)
      })
    }

    return () => {
      isMounted.current = false
    }
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        setLoad(true)
      }
    }, loadDelay)
  }, [siteId, loadDelay])

  const status = useScript(`https://www.google.com/recaptcha/api.js?render=${siteId}`, {
    id: 'recaptcha',
    shouldPreventLoad: !load,
  })

  React.useEffect(() => {
    if (status !== 'ready') {
      return
    }

    (window as any).grecaptcha.ready(() => {
      setRecaptcha((window as any).grecaptcha)
    })
  }, [status])

  function execute(action: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (recaptcha) {
        resolve(recaptcha.execute(siteId, { action }))
      } else {
        reject(new Error('recaptcha not available'))
      }
    })
  }

  return {
    loading: status !== 'ready',
    execute
  }
}

const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined'

function injectStyle(rule: string) {
  const styleEl = document.createElement('style') as HTMLStyleElement
  document.head.appendChild(styleEl)

  const styleSheet = styleEl.sheet as CSSStyleSheet
  if (styleSheet) styleSheet.insertRule(rule, styleSheet.cssRules.length)
}