import * as React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useScrollPosition } from "../../hooks"
import { Container } from "../Container"
import { Tab, TabMenu, TabButton } from "../Tab"
import { Select } from "./Select"

type NavigationProps = {
  items?: NavigationItem[]
  sticky?: boolean
  loading?: boolean
}

type NavigationItem = {
  to: string
  content: string
  ref?: React.MutableRefObject<HTMLElement>
  active?: boolean
}

export function Navigation(props: NavigationProps): React.JSX.Element {
  const {
    sticky = false,
    loading = false,
    items = [],
  } = props

  const JUMP_DELAY = 1000
  const SPACE_TO_AVOID_DOUBLE_ACTIVE = 60
  const navRef = React.useRef<HTMLDivElement>()
  const [navItems, setNavItems] = React.useState<NavigationItem[]>(items)
  const [selectedMenu, setSelectedMenu] = React.useState(undefined)
  const { pathname, hash } = useLocation()
  const { y: scrollY } = useScrollPosition()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (loading) {
      return
    }

    if (!navRef.current) {
      return
    }

    // @note: handle redirected url (e.g: /about-us -> /about-us/)
    const pathName = pathname.endsWith("/") ? pathname.slice(0, pathname.length - 1) : pathname
    const navRect = navRef.current.getBoundingClientRect()
    const navBottom = navRect.top + navRect.height

    setNavItems((prevState) => {
      const sections = prevState
        .map((item, i: number) => ({ ...item, actualIndex: i }))
        .filter((item) => item.ref && item.ref.current)
      if (sections.length > 0) {
        const firstSection = sections[0]
        const firstRect = firstSection.ref.current.getBoundingClientRect()
        const isIntersectFirst = firstRect.top < navBottom
        if (!isIntersectFirst) {
          return prevState.map((item, i: number) => {
            return {
              ...item,
              active: i === firstSection.actualIndex
            }
          })
        }

        const lastSection = sections[sections.length - 1]
        const lastRect = lastSection.ref.current.getBoundingClientRect()
        const isIntersectLast = lastRect.top < navBottom
        if (isIntersectLast) {
          return prevState.map((item, i: number) => {
            return {
              ...item,
              active: i === lastSection.actualIndex
            }
          })
        }
      }

      return prevState.map((item) => {
        const pathMatch = (item.to === pathName)
        const itemHasNoId = item.to.indexOf("#") === -1
        const itemHasNoRef = !item.ref || !item.ref.current

        if (itemHasNoId || itemHasNoRef) {
          return {
            ...item,
            active: pathMatch
          }
        }

        const itemRect = item.ref.current.getBoundingClientRect()
        const itemBottom = itemRect.top + itemRect.height
        const isIntersect = navRect.top <= (itemBottom - SPACE_TO_AVOID_DOUBLE_ACTIVE) && navBottom > itemRect.top

        return {
          ...item,
          active: isIntersect
        }
      })
    })
  }, [scrollY, pathname, loading])

  React.useEffect(() => {
    if (loading) {
      return
    }

    const hashId = hash.startsWith("#") ? hash.replace("#", "") : null
    if (!hashId) {
      return
    }

    setTimeout(() => {
      setNavItems((prevState) => {
        return prevState.map((item) => {
          const id = item.to.indexOf("#") !== - 1 ? item.to.substring(item.to.indexOf("#") + 1) : null
          if (!id) {
            return item
          }

          return {
            ...item,
            active: id === hashId
          }
        })
      })

      const section = document.getElementById(hashId)
      if (!section) {
        return
      }

      section.scrollIntoView({
        behavior: "smooth",
      })
    }, JUMP_DELAY)
  }, [hash, loading])

  React.useEffect(() => {
    const activeIdx = navItems.findIndex((item) => item.active)
    if (activeIdx === -1) {
      return
    }

    const item = navItems[activeIdx]
    setSelectedMenu({
      value: item.to,
      label: item.content,
      index: activeIdx
    })
  }, [navItems])

  return (
    <div ref={navRef} className={
      "w-full h-full bg-sta-navy py-4 xl:overflow-x-auto " +
      (sticky ? "sticky top-[64px] xl:top-[100px] left-0 z-[5]" : "relative z-[1]")
    }>
      <Container size="md">
        <Tab appendClassNames="hidden xl:block">
          <TabMenu className="w-full flex flex-row flex-nowrap gap-3 lg:gap-6 justify-start 2xl:justify-center items-center">
            {
              navItems.map((item, i: number) => {
                return (
                  <React.Fragment key={`nav-item-${i}`}>
                    <TabButton to={item.to} active={item.active} appendClassNames="min-w-fit">
                      <span className="uppercase">
                        {item.content}
                      </span>
                    </TabButton>
                  </React.Fragment>
                )
              })
            }
          </TabMenu>
        </Tab>
        <Select
          appendClassNames="uppercase block xl:hidden"
          value={selectedMenu}
          onUpdate={({ value }) => {
            navigate(value)
          }}
          options={navItems.map((item, i: number) => {
            return {
              value: item.to,
              label: item.content,
              index: i
            }
          })}>
        </Select>
      </Container>
    </div>
  )
}