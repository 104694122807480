import * as React from "react"

type FooterProps = {
  children?: React.ReactNode
  sticky?: boolean
  appendClassNames?: string
}

export function Base(props: FooterProps): React.JSX.Element {
  const {
    appendClassNames = ""
  } = props

  return (
    <footer className={appendClassNames}>
      {props.children}
    </footer>
  )
}
