import * as React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import ReactGA from "react-ga4"
import { HeaderProvider } from "../Header"
import { MetaProvider } from "../Meta"
import { routes } from "../../routes"
import "./App.css"
import "./HtmlViewer.css"

ReactGA.send({ hitType: "pageview", page: window.location.pathname })

const meta = {
  titleTemplate: "Singapore Transport Association | %s",
  titleFallback: "Singapore Transport Association",
  descriptionFallback: "Singapore Transport Association",
  keywordsFallback: "sta, singapore, transport, association, business, solution, efficient, technology, container",
  typeFallback: "website",
  urlFallback: window.location.href,
  imageFallback: `${process.env.REACT_APP_PUBLIC_URL}/image/sta-meta.jpg`,
  markupSchemaFallback: {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Singapore Transport Association",
    "image": "https://sta.sg/image/sta-meta.jpg",
    "@id": "https://sta.sg/",
    "url": "https://sta.sg/",
    "telephone": "+6568420853",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "9 Jurong Town Hall Rd, Singapore 609431",
      "addressLocality": "Singapore",
      "postalCode": "609431",
      "addressCountry": "SG"
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "00:00",
      "closes": "00:00"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 1.3287301,
      "longitude": 103.7417687
    },
    "sameAs": [
      "https://www.facebook.com/people/Singapore-Transport-Association/100090536295667/",
      "https://www.linkedin.com/company/singapore-transport-association",
      "https://www.instagram.com/sg.transport.association/"
    ]
  },
  twitterSchemaFallback: {
    "card": "summary_large_image",
  }
}
export default function App(): React.JSX.Element {
  return (
    <MetaProvider meta={meta}>
      <HeaderProvider>
        <Router>
          <Routes>
            {
              routes.map((route, i) => {
                return <Route {...route} key={`route-item-${i}`} />
              })
            }
          </Routes>
        </Router>
      </HeaderProvider>
    </MetaProvider>
  )
}