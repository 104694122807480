import * as React from "react"
import { HiPlus, HiMinus } from "react-icons/hi2"
import { Base } from "./Base"
import styles from "./InputCounter.module.css"
import {
  InputSize,
  InputSizeProps,
} from "./Resource"

const themes = new Map<string, Map<string, string>>()

const defaultTheme = new Map<string, string>()
defaultTheme.set("container", "flex flex-row rounded outline outline-1 outline-slate-100")
defaultTheme.set("element", styles['input-counter'] + " w-full border text-center")
defaultTheme.set("disabled-true", "cursor-not-allowed")
defaultTheme.set(`size-${InputSize.LARGE}`, "px-2 py-3")
defaultTheme.set(`size-${InputSize.MEDIUM}`, "p-2")

const staTheme = new Map<string, string>()
staTheme.set("container", "flex flex-row rounded outline outline-1 outline-slate-100")
staTheme.set("element", styles['input-counter'] + " w-full bg-sta-cloud border-1 md:border-2 border-sta-primary text-center")
staTheme.set("disabled-true", "cursor-not-allowed")
staTheme.set(`size-${InputSize.LARGE}`, "px-2 py-3")
staTheme.set(`size-${InputSize.MEDIUM}`, "p-2")

themes.set("", defaultTheme)
themes.set("sta", staTheme)

class InputCounterStyle {

  private theme: string = ""
  private disabled: boolean = false
  private size: string = ""
  private elementClassNames: string | undefined = ""

  public buildElement(): string | undefined {
    let style: string | undefined = undefined

    if (this.elementClassNames) {
      return this.elementClassNames
    }

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("element")) {
      style = theme.get("element")
    }

    if (theme.has(`disabled-${this.disabled}`)) {
      style += " " + theme.get(`disabled-${this.disabled}`)
    }

    if (theme.has(`size-${this.size}`)) {
      style += " " + theme.get(`size-${this.size}`)
    }

    return style
  }

  public buildContainer(): string | undefined {
    let style: string | undefined = undefined

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("container")) {
      style = theme.get("container")
    }

    return style
  }

  public setTheme(theme: string): InputCounterStyle {
    this.theme = theme
    return this
  }

  public setDisabled(disabled: boolean): InputCounterStyle {
    this.disabled = disabled
    return this
  }

  public setSize(size: string): InputCounterStyle {
    this.size = size
    return this
  }

  public setElementClassNames(elementClassNames?: string): InputCounterStyle {
    this.elementClassNames = elementClassNames
    return this
  }
}

type InputCounterProps = {
  id?: string
  name?: string
  placeholder?: string
  defaultValue?: number | undefined
  value?: number | undefined
  style?: React.CSSProperties | undefined
  size?: InputSizeProps
  className?: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  onUpdate?: (p: UpdateMessage) => void
  onDecrease?: () => void
  onIncrease?: () => void
}

type UpdateMessage = {
  value: number
}

const styleBuilder = new InputCounterStyle()

export function InputCounter(props: InputCounterProps): React.JSX.Element {
  const {
    disabled = false,
    size = InputSize.MEDIUM,
    value = 0,
    className,
    style,
  } = props

  styleBuilder
    .setElementClassNames(className)
    .setDisabled(disabled)
    .setSize(size)
    .setTheme("sta")

  const containerClassNames = styleBuilder.buildContainer()
  const elementClassNames = styleBuilder.buildElement()

  const handleDecrease = () => {
    props.onDecrease && props.onDecrease()
  }

  const handleIncrease = () => {
    props.onIncrease && props.onIncrease()
  }

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    let val = e.currentTarget.value
    updateValue(val)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.currentTarget.value
    updateValue(val)
  }

  function updateValue(val?: string) {
    if (!props.onUpdate) {
      return
    }

    if (!val) {
      props.onUpdate({
        value: Number(0),
      })
      return
    }

    val = val.replace(/[^0-9-]/g, "")
    if (val === "-") {
      props.onUpdate({
        value: Number(-1),
      })
      return
    }

    props.onUpdate({
      value: Number(val),
    })
  }

  return (
    <div className={containerClassNames}>
      <button type="button" className="bg-white p-2 border rounded-tl rounded-bl" onClick={handleDecrease}>
        <HiMinus className="w-6 h-6" aria-hidden="true" />
      </button>

      <Base
        type="number"
        value={value}
        onInput={handleInput}
        className={elementClassNames}
        onChange={handleChange}
        style={style}
        {...props} />

      <button type="button" className="bg-white p-2 border rounded-tr rounded-br" onClick={handleIncrease}>
        <HiPlus className="w-6 h-6" aria-hidden="true" />
      </button>
    </div>
  )
}