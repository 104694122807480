import * as React from "react"
import {
  FieldNotifVariant,
  FieldNotifVariantProps,
  FieldNotifStyle
} from "./Resource"

type FieldProps = {
  children?: React.ReactNode
}

export function Field(props: FieldProps): React.JSX.Element {
  return (
    <>{props.children}</>
  )
}

type FieldNotifProps = {
  children?: React.ReactNode
  variant?: FieldNotifVariantProps
}

const styleBuilder = new FieldNotifStyle()

export function FieldNotif(props: FieldNotifProps): React.JSX.Element {
  const {
    variant = FieldNotifVariant.DANGER
  } = props

  const classNames = styleBuilder.setVariant(variant).build()

  return (
    <>
      {
        props.children &&
        <span className={classNames}>
          {props.children}
        </span>
      }
    </>
  )
}
