import * as React from "react"
import { LuUpload } from "react-icons/lu"
import { Base } from "./Base"
import {
  InputSize,
  InputSizeProps
} from "./Resource"

const themes = new Map<string, Map<string, string>>()

const defaultTheme = new Map<string, string>()
defaultTheme.set("element", "flex flex-row gap-2 md:gap-5 w-full border rounded outline outline-1 outline-slate-100")
defaultTheme.set("disabled-false", "cursor-pointer")
defaultTheme.set("disabled-true", "cursor-not-allowed")
defaultTheme.set("placeholder", "font-semibold text-base md:text-lg text-black")
defaultTheme.set("note", "text-base text-gray-600")
defaultTheme.set("icon", "w-12 h-12 rounded-full bg-gray-600 text-white flex justify-center items-center")
defaultTheme.set(`size-${InputSize.LARGE}`, "px-2 py-3")
defaultTheme.set(`size-${InputSize.MEDIUM}`, "p-2")

const staTheme = new Map<string, string>()
staTheme.set("element", "flex flex-row gap-2 md:gap-5 w-full bg-sta-cloud border-1 md:border-2 border-dashed border-sta-primary rounded outline outline-1 outline-slate-100")
staTheme.set("disabled-false", "cursor-pointer")
staTheme.set("disabled-true", "cursor-not-allowed")
staTheme.set("placeholder", "font-semibold text-base md:text-lg text-sta-primary")
staTheme.set("note", "text-base text-sta-gray")
staTheme.set("icon", "w-12 h-12 rounded-full bg-sta-secondary text-white flex justify-center items-center")
staTheme.set(`size-${InputSize.LARGE}`, "px-2 py-3")
staTheme.set(`size-${InputSize.MEDIUM}`, "px-4 md:px-8 py-3 md:py-6")

themes.set("", defaultTheme)
themes.set("sta", staTheme)

class InputFileStyle {

  private theme: string = ""
  private disabled: boolean = false
  private size: string = ""
  private elementClassNames: string | undefined = ""
  private appendedClassNames: string | undefined = ""

  public buildElement(): string | undefined {
    let style: string | undefined = undefined

    if (this.elementClassNames) {
      return this.elementClassNames
    }

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("element")) {
      style = theme.get("element")
    }

    if (theme.has(`size-${this.size}`)) {
      style += " " + theme.get(`size-${this.size}`)
    }

    if (theme.has(`disabled-${this.disabled}`)) {
      style += " " + theme.get(`disabled-${this.disabled}`)
    }

    if (this.appendedClassNames && this.appendedClassNames !== "") {
      style += " " + this.appendedClassNames
    }

    return style
  }

  public buildPlaceholder(): string | undefined {
    let style: string | undefined = undefined

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("placeholder")) {
      style = theme.get("placeholder")
    }

    return style
  }

  public buildNote(): string | undefined {
    let style: string | undefined = undefined

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("note")) {
      style = theme.get("note")
    }

    return style
  }

  public buildIcon(): string | undefined {
    let style: string | undefined = undefined

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("icon")) {
      style = theme.get("icon")
    }

    return style
  }

  public setTheme(theme: string): InputFileStyle {
    this.theme = theme
    return this
  }

  public setDisabled(disabled: boolean): InputFileStyle {
    this.disabled = disabled
    return this
  }

  public setSize(size: string): InputFileStyle {
    this.size = size
    return this
  }

  public setElementClassNames(elementClassNames?: string): InputFileStyle {
    this.elementClassNames = elementClassNames
    return this
  }

  public setAppendedClassNames(appendedClassNames?: string): InputFileStyle {
    this.appendedClassNames = appendedClassNames
    return this
  }
}

type InputFileProps = {
  id?: string
  name?: string
  value?: File
  placeholder?: string
  selectedPlaceholder?: string
  note?: string
  style?: React.CSSProperties | undefined
  size?: InputSizeProps
  accepts?: string[]
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void
  onUpdate?: (m: UpdateMessage) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
}

type UpdateMessage = {
  value?: File
}

const styleBuilder = new InputFileStyle()

export function InputFile(props: InputFileProps): React.JSX.Element {
  const {
    required = false,
    disabled = false,
    readOnly = false,
    size = InputSize.MEDIUM,
    accepts = [],
    placeholder = "Click to upload",
    selectedPlaceholder = "File selected",
    note,
    value,
    onUpdate = () => { },
  } = props

  const [file, setFile] = React.useState<File>(value)

  styleBuilder
    .setDisabled(disabled)
    .setSize(size)
    .setTheme("sta")

  const elementClassNames = styleBuilder.buildElement()
  const placeholderClassNames = styleBuilder.buildPlaceholder()
  const noteClassNames = styleBuilder.buildNote()
  const iconClassNames = styleBuilder.buildIcon()

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target?.files
    if (files.length === 0) {
      setFile(undefined)
      onUpdate && onUpdate({
        value: undefined
      })
      return
    }

    const file = files.item(0)
    setFile(file)
    onUpdate && onUpdate({
      value: file
    })
  }

  return (
    <>
      <Base
        id={props.id}
        name={props.name}
        placeholder={props.placeholder}
        style={props.style}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        type="file"
        className={"hidden"}
        accept={accepts.join(",")}
        onChange={handleFileChange}
        onInput={props.onInput}
        onBlur={props.onBlur} />
      <label className={elementClassNames} htmlFor={props.id}>
        <div className={iconClassNames}>
          <LuUpload className="w-6 h-6" aria-hidden="true" />
        </div>
        <div className="flex flex-col justify-center items-start">
          <span className={placeholderClassNames}>
            {file ? selectedPlaceholder : placeholder}
          </span>
          {
            note &&
            <span className={noteClassNames}>
              {note}
            </span>
          }
        </div>
      </label>
    </>
  )
}