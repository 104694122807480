import * as React from "react"
import { Base } from "./Base"
import { HiMagnifyingGlass } from "react-icons/hi2"
import {
  InputSize,
  InputSizeProps
} from "./Resource"

const themes = new Map<string, Map<string, string>>()

const defaultTheme = new Map<string, string>()
defaultTheme.set("container", "flex flex-row rounded outline outline-1 outline-slate-100")
defaultTheme.set("element-left", "w-full border rounded-tr rounded-br outline outline-1 outline-slate-100")
defaultTheme.set("element-right", "w-full border rounded-tl rounded-bl outline outline-1 outline-slate-100")
defaultTheme.set("action-left", "bg-white text-gray-500 border rounded-tl rounded-bl outline outline-1 outline-slate-100")
defaultTheme.set("action-right", "bg-white text-gray-500 border rounded-tr rounded-br outline outline-1 outline-slate-100")
defaultTheme.set("disabled-true", "cursor-not-allowed")
defaultTheme.set(`size-${InputSize.LARGE}`, "px-2 py-3")
defaultTheme.set(`size-${InputSize.MEDIUM}`, "p-2")

const staTheme = new Map<string, string>()
staTheme.set("container", "flex flex-row bg-white rounded-[45px] md:rounded-[55px]")
staTheme.set("element-left", "w-full mr-4 bg-transparent placeholder:text-sta-primary")
staTheme.set("element-right", "w-full ml-4 bg-transparent placeholder:text-sta-primary")
staTheme.set("action-left", "ml-4 text-sta-primary")
staTheme.set("action-right", "mr-4 text-sta-primary")
staTheme.set("disabled-true", "cursor-not-allowed")
staTheme.set(`size-${InputSize.LARGE}`, "px-2 py-3")
staTheme.set(`size-${InputSize.MEDIUM}`, "p-2")

themes.set("", defaultTheme)
themes.set("sta", staTheme)

class InputSearchStyle {

  private theme: string = ""
  private disabled: boolean = false
  private size: string = ""
  private actionPosition: string = ""
  private elementClassNames: string | undefined = ""

  public buildContainer(): string | undefined {
    let style: string | undefined = undefined

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("container")) {
      style = theme.get("container")
    }

    if (theme.has(`disabled-${this.disabled}`)) {
      style += " " + theme.get(`disabled-${this.disabled}`)
    }

    return style
  }

  public buildElement(): string | undefined {
    let style: string | undefined = undefined

    if (this.elementClassNames) {
      return this.elementClassNames
    }

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has(`element-${this.actionPosition}`)) {
      style = " " + theme.get(`element-${this.actionPosition}`)
    }

    if (theme.has(`size-${this.size}`)) {
      style += " " + theme.get(`size-${this.size}`)
    }

    return style
  }

  public buildAction(): string | undefined {
    let style: string | undefined = undefined

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has(`action-${this.actionPosition}`)) {
      style = " " + theme.get(`action-${this.actionPosition}`)
    }

    if (theme.has(`size-${this.size}`)) {
      style += " " + theme.get(`size-${this.size}`)
    }

    return style
  }

  public setTheme(theme: string): InputSearchStyle {
    this.theme = theme
    return this
  }

  public setDisabled(disabled: boolean): InputSearchStyle {
    this.disabled = disabled
    return this
  }

  public setSize(size: string): InputSearchStyle {
    this.size = size
    return this
  }

  public setActionPosition(actionPosition: string): InputSearchStyle {
    this.actionPosition = actionPosition
    return this
  }

  public setElementClassNames(elementClassNames?: string): InputSearchStyle {
    this.elementClassNames = elementClassNames
    return this
  }
}

type ActionPositionProps = "left" | "right"

enum ActionPosition {
  LEFT = "left",
  RIGHT = "right"
}

type InputSearchProps = {
  id?: string
  name?: string
  placeholder?: string
  defaultValue?: string | number | ReadonlyArray<string> | undefined
  value?: string | ReadonlyArray<string> | number | undefined
  style?: React.CSSProperties | undefined
  className?: string
  size?: InputSizeProps
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  showAction?: boolean
  actionPosition?: ActionPositionProps
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
  onSubmit?: () => void
}

const styleBuilder = new InputSearchStyle()

export function InputSearch(props: InputSearchProps): React.JSX.Element {
  const {
    disabled = false,
    showAction = false,
    size = InputSize.MEDIUM,
    actionPosition = ActionPosition.RIGHT,
    className
  } = props
  
  styleBuilder
    .setElementClassNames(className)
    .setActionPosition(actionPosition)
    .setDisabled(disabled)
    .setSize(size)
    .setTheme("sta")

  const containerClassNames = styleBuilder.buildContainer()
  const actionClassNames = styleBuilder.buildAction()
  const elementClassNames = styleBuilder.buildElement()

  const handleSubmit = () => {
    props.onSubmit && props.onSubmit()
  }

  return (
    <div className={containerClassNames}>
      {
        showAction && actionPosition === ActionPosition.LEFT &&
        <ButtonSearch className={actionClassNames} onClick={handleSubmit} />
      }

      <Base
        type="search"
        className={elementClassNames}
        {...props} />

      {
        showAction && actionPosition === ActionPosition.RIGHT &&
        <ButtonSearch className={actionClassNames} onClick={handleSubmit} />
      }
    </div>
  )
}

type ButtonSearchProps = {
  className?: string
  onClick?: () => void
}

function ButtonSearch(props: ButtonSearchProps): React.JSX.Element {
  const {
    className,
    onClick
  } = props

  return (
    <button className={className} onClick={onClick}>
      <HiMagnifyingGlass className="w-6 h-6 stroke-2" />
    </button>
  )
}