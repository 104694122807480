import * as React from "react"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6"
import {
  CarouselVariant,
  CarouselVariantProps,
  NavigationPosition,
  NavigationPositionProps,
  NavigationVariant,
  NavigationVariantProps,
  NavigationStyle
} from "./Resource"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper/modules"
import { Swiper as S } from "swiper/types"
import "swiper/css"

type CarouselProps = {
  children?: {
    items: CarouselItem[]
  }
  className?: string
  autoplay?: boolean
  autoplayDelay?: number
  grabable?: boolean
  navigable?: boolean
  variant?: CarouselVariantProps
  navPosition?: NavigationPositionProps
  slidesPerView?: number | 'auto'
  spaceBetween?: number
  breakpoints?: {
    [w: number]: CarouselOption
  }
}

type CarouselItem = {
  children?: React.ReactNode
  className?: string
}

type CarouselOption = {
  slidesPerView?: number | 'auto'
  spaceBetween?: number
}

export function Carousel(props: CarouselProps): React.JSX.Element {
  const {
    autoplay = false,
    autoplayDelay = 5000,
    grabable = false,
    navigable = false,
    variant = CarouselVariant.PRIMARY,
    navPosition = NavigationPosition.MIDDLE,
    breakpoints = {},
    children = {
      items: []
    },
  } = props

  const items = children.items
  const [carousel, setCarousel] = React.useState<S>(null)
  const [navigation, setNavigation] = React.useState({
    activeIndex: 0,
    isBeginning: false,
    isEnd: false,
  })

  const handlePrev = () => {
    if (!carousel) {
      return
    }

    carousel.slidePrev()

    setNavigation((prevState) => {
      return {
        ...prevState,
        activeIndex: carousel.realIndex,
        isBeginning: carousel.isBeginning,
        isEnd: carousel.isEnd,
      }
    })
  }

  const handleNext = () => {
    if (!carousel) {
      return
    }
    carousel.slideNext()

    setNavigation((prevState) => {
      return {
        ...prevState,
        activeIndex: carousel.realIndex,
        isBeginning: carousel.isBeginning,
        isEnd: carousel.isEnd,
      }
    })
  }

  React.useEffect(() => {
    if (!carousel) {
      return
    }

    setNavigation((prevState) => {
      return {
        ...prevState,
        activeIndex: carousel.realIndex,
        isBeginning: carousel.isBeginning,
        isEnd: carousel.isEnd,
      }
    })

    if (carousel.autoplay) {
      if (autoplay) {
        carousel.autoplay.start()
      } else {
        carousel.autoplay.stop()
      }
    }
  }, [carousel, autoplay])

  React.useEffect(() => {
    if (!carousel) {
      return
    }

    setNavigation((prevState) => {
      return {
        ...prevState,
        activeIndex: carousel.realIndex,
        isBeginning: carousel.isBeginning,
        isEnd: carousel.isEnd,
      }
    })

    carousel.update()
  }, [carousel, items])

  function setupCarousel(c: S) {
    setCarousel(c)

    setNavigation((prevState) => {
      return {
        ...prevState,
        activeIndex: c.realIndex,
        isBeginning: c.isBeginning,
        isEnd: c.isEnd,
      }
    })
  }

  return (
    <CarouselContainer>
      <CarouselNavigation
        variant={variant}
        enabled={navigable}
        position={navPosition}
        prevDisabled={navigation.isBeginning}
        nextDisabled={navigation.isEnd}
        onPrev={handlePrev}
        onNext={handleNext} />

      <Swiper
        enabled
        modules={[Autoplay]}
        autoplay={{
          delay: autoplayDelay,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        slidesPerView={props.slidesPerView}
        spaceBetween={props.spaceBetween}
        breakpoints={breakpoints}
        grabCursor={grabable}
        wrapperClass={props.className}
        // @issue: https://stackoverflow.com/questions/70802682/parents-overflowhidden-doesnt-show-childs-box-shadow
        className="h-full !overflow-clip [overflow-clip-margin:5px] sm:[overflow-clip-margin:10px]"
        onSwiper={(s) => { setupCarousel(s) }}
        onRealIndexChange={(s) => { setupCarousel(s) }}>
        {
          items.map((item, i: number) => {
            return (
              <SwiperSlide key={`carousel-item-${i}`} className={item.className}>
                {item.children}
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </CarouselContainer>
  )
}

type CarouselContainerProps = {
  children?: React.ReactNode
}

function CarouselContainer(props: CarouselContainerProps): React.JSX.Element {
  return (
    <div className="relative w-full h-full">
      {props.children}
    </div>
  )
}

type CarouselNavigationProps = {
  enabled?: boolean
  position?: NavigationPositionProps
  variant?: NavigationVariantProps
  prevDisabled?: boolean
  nextDisabled?: boolean
  onPrev?: () => void
  onNext?: () => void
}

const navigationStyle = new NavigationStyle()

function CarouselNavigation(props: CarouselNavigationProps): React.JSX.Element {
  const {
    enabled = false,
    position = NavigationPosition.MIDDLE,
    variant = NavigationVariant.PRIMARY,
    prevDisabled = false,
    nextDisabled = false,
  } = props

  navigationStyle
    .setPosition(position)
    .setVariant(variant)

  const containerClassnames = navigationStyle.buildContainer()
  const buttonClassnames = navigationStyle.buildButton()
  const iconClassnames = navigationStyle.buildIcon()

  return (
    <>
      {
        enabled && position.startsWith("t") &&
        <div className={containerClassnames + (position === "tl" ? " justify-start" : " justify-end")}>
          <button aria-label="previous" onClick={props.onPrev}
            className={buttonClassnames} disabled={prevDisabled}>
            <FaChevronLeft className={iconClassnames} aria-hidden="true" />
          </button>
          <button aria-label="next" onClick={props.onNext}
            className={buttonClassnames} disabled={nextDisabled}>
            <FaChevronRight className={iconClassnames} aria-hidden="true" />
          </button>
        </div>
      }
      {
        enabled && position === "mid" &&
        <>
          <button aria-label="previous" onClick={props.onPrev}
            className={"left-[10px] " + buttonClassnames} disabled={prevDisabled}>
            <FaChevronLeft className={iconClassnames} aria-hidden="true" />
          </button>
          <button aria-label="next" onClick={props.onNext}
            className={"right-[10px] " + buttonClassnames} disabled={nextDisabled}>
            <FaChevronRight className={iconClassnames} aria-hidden="true" />
          </button>
        </>
      }
    </>
  )
}

export {
  SwiperSlide as CarouselItem
}